import React from "react";

import SEO from "../components/SEO";
import NavSection from "../components/partials/SubPagesNav";
import JumbotronSection from "../components/partials/contact/JumbotronSection";
import ContactDetailsSection from "../components/partials/contact/ContactDetailsSection";

const ContactPage = () => {
  return (
    <>
      <SEO
        title="page_titles.contact"
        description="page_descriptions.contact"
        image="https://static.classdojo.com/img/page_contact/contactusFBimage.png"
      />
      <NavSection active="contact" />
      <JumbotronSection />
      <ContactDetailsSection />
    </>
  );
};
export default ContactPage;
